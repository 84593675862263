import * as Highcharts from "highcharts";
import "jquery-typeahead";
import { appendSchoolsToExport } from "../export";

$(function () {
    // Generate the regions list from the json file for auto complete
    $.ajax('../Data/RegionInfo.json?111111', {
        success: function (data) {
            addSchoolSearchTypeahead(data.lstLEA)
        },
        error: function (error) {
            console.error('Failed to retrieve region info.', {error});
        }
    });
});

function getMaxOfArray(numArray) {
    return Math.max.apply(null, numArray);
}
window.getMaxOfArray = getMaxOfArray;

/**
 * Creates the chart to display the series data. The chart will be added to the global `window.allCharts` list
 * 
 * @param {string} indicatorId 
 * @param {string} chartId 
 * 
 * @returns {import("../types").ChartObjectWithIndicator} The chart that was drawn
 */
function drawChart(indicatorId, chartId) {

    /** @type {import("../types").ChartObjectWithIndicator } */
    let chart = {};

    // Create object to hold data
    var jsonModel = {};
    var w = window.innerWidth;

    // Loop through each indicator available
    for (var k = 0; k < chartData.length; k++) {
        if (chartData[k].IndicatorId == indicatorId) {
            jsonModel = chartData[k]
        }
    }

    var selectedChart = {};
    // Get the desired chart from the indicator and show/hide ppropriate tables
    for (var l = 0; l < jsonModel.lstCharts.length; l++) {
        var isSelectedChart = jsonModel.lstCharts[l].ChartId == chartId;

        var tableForChart = $("#atbl" + jsonModel.lstCharts[l].ChartId);
        if (tableForChart != null) {
            if (isSelectedChart) {
                $(tableForChart).show();
            } else {
                $(tableForChart).hide();
            }
        }
        if (isSelectedChart) {
            var selectedChart = jsonModel.lstCharts[l];
        }
    }

    //set the correct button style for the selected chart
    $("#testing" + indicatorId + " div.buttonSpacers a").each(function (index) {
        if ($(this).attr('id') == "chart" + chartId) {
            $(this).addClass("clickedToggle");
            $(this).removeClass("initialToggle");
        } else {
            $(this).addClass("initialToggle");
            $(this).removeClass("clickedToggle");
        }
    });

    // Check if the sectionid passed in is 9 (this is for sport)
    if (jsonModel.SectionId == 9) {

        // Create an array to hold the data series to build up as it is a bit different to the other series
        var seriesList = [];

        // Loop through each record in the jsonModel.lstSeries array
        for (var i = 0; i < selectedChart.lstSeries.length; i++) {
            // Check if the english value for the series name = school
            if (selectedChart.lstSeries[i].nameEnglish == "School") {
                // If this matches, add this to the new seriesList array
                seriesList.push(selectedChart.lstSeries[i]);
                // Break out of the loop as it does not need to continue
                break;
            }

        }

        // Create the chart
        chart = new Highcharts.Chart({
            chart: {
                type: 'column',
                renderTo: 'ind' + indicatorId
            },
            title: {
                text: ''
            },
            credits: { enabled: false },
            legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom',
                itemStyle: {
                    color: '#333333',
                    fontFamily: 'Arial, sans-serif',
                    fontSize: '9pt'
                },
                padding: 14,
                itemMarginTop: 4,
                itemMarginBottom: 0,
                itemWidth: 136,
                borderRadius: 0,
                borderColor: '#f6f6f6',
                backgroundColor: '#f6f6f6',
                borderWidth: 4,
                x: -6,
                y: 0,
                width: 160,
                reversed: false,
                enabled: true
            },
            xAxis: {
                categories: selectedChart.lstYears,
                crosshair: true
            },
            yAxis: {
                min: 0,
                max: jsonModel.SubjectName.indexOf("MW") > -1 ? null : 100,
                title: {
                    text: selectedChart.UnitOfMeasurement
                }
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: selectedChart.lstSeries
        });
    }
    else if (jsonModel.SubjectName == "PUP") {
        // A check is made if the subject name provided is "PUP" for the pupil count chart as this is 
        // slightly different
        var maxCount = 0;  // hard coding value for now

        // Create a variable to hold the total number of year groups for the school
        var totalSeries = 0;

        // Create an array to hold the data series as it needs to be slightly different for this type of chart
        var seriesData = [];

        // Loop through each record in the jsonModel.lstSeries array
        for (var x = 0; x < selectedChart.lstSeries.length - 1; x++) {
            // Create a series object
            var ser = {};
            // Create an array
            var data = [];

            // Check if the series name is male
            if (selectedChart.lstSeries[x].name.toLowerCase() == "male") {
                // Allocate the value of the name to the series object
                ser.name = selectedChart.lstSeries[x].name;

                // Loop through each piece of data in jsonModel.lstSeries
                for (var k = 0; k < selectedChart.lstSeries[x].data.length - 1; k++) {
                    // Multiply the data value by -1 and allocate it to the data array
                    // This is done to achieve the split column effect
                    data.push(selectedChart.lstSeries[x].data[k] * -1);

                }

                // Set more values for the series object
                ser.data = data;
                ser.chartOmit = false;
            }

            // Check if the series name is female
            if (selectedChart.lstSeries[x].name.toLowerCase() == "female") {
                // Allocate the value of the name to the series object
                ser.name = selectedChart.lstSeries[x].name;
                // Loop through each piece of data in jsonModel.lstSeries
                for (var k = 0; k < selectedChart.lstSeries[x].data.length - 1; k++) {

                    // Multiply the data value by 1 and allocate it to the data array
                    // This is done to achieve the split column effect
                    data.push(selectedChart.lstSeries[x].data[k] * 1);

                }

                // Set more values for the series object
                ser.data = data;
                ser.chartOmit = false;
            }

            // Add each series object to the seriesData array
            seriesData.push(ser);

        }

        var totals = [];
        // Loop through each record in the seriesData array
        for (var t = 0; t < seriesData.length; t++) {

            for (var p = 0; p < seriesData[t].data.length; p++) {
                // Add the pupil count to the data array where there is a count >= 0
                if (seriesData[t].data[p] >= 0 || seriesData[t].data[p] * -1 >= 0) {
                    if (seriesData[t].data[p] < 0) {
                        totals.push(seriesData[t].data[p] * -1);
                    }
                    else {
                        totals.push(seriesData[t].data[p]);
                    }
                }
            }

            // Check if the data property is undefined
            if (seriesData[t].data == undefined) {
                // If so, set the showInLegend property to be false
                // This ensures that the "School" record does not appear in the chart legend
                seriesData[t].showInLegend = false;
            }

        }


        // Get the maximum number of pupils in a year group to set min and max figures for the chart
        maxCount = getMaxOfArray(totals);

        maxCount = maxCount + 8;

        // Set the chart tick interval dependant on pupil numbers
        var tickInterval = 2;
        if (maxCount <= 10) {
            tickInterval = 2;
        }
        else if (maxCount > 10 && maxCount <= 20) {
            tickInterval = 5;
        }
        else if (maxCount > 20 && maxCount <= 50) {
            tickInterval = 10;
        }
        else if (maxCount > 50) {
            tickInterval = 50;
        }

        // Create an array to hold the indicator name split by commas
        var txtArr = jsonModel.IndicatorName.split(",");

        // Get the table relevant to the chart
        var table = $("#atbl" + chartId);

        //// If the screen size is less than 1350
        if (w > 850) {
            // Add a specific class to allow the table to be scrollable as it can get quite large for middle schools
            table.addClass("pupilTable");

        }

        // Create the chart
        chart = new Highcharts.Chart({
            chart: {
                animation: false,
                backgroundColor: 'none',
                renderTo: 'ind' + indicatorId,
                type: 'bar',
                style: {
                    fontFamily: 'Arial, sans-serif',
                    fontSize: '10pt'
                },
            },
            legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom',
                itemStyle: {
                    color: '#333333',
                    fontFamily: 'Arial, sans-serif',
                    fontSize: '9pt'
                },
                padding: 14,
                itemMarginTop: 4,
                itemMarginBottom: 0,
                itemWidth: 136,
                borderRadius: 0,
                borderColor: '#f6f6f6',
                backgroundColor: '#f6f6f6',
                borderWidth: 4,
                x: -6,
                y: 0,
                width: w < 750 ? 100 : 140
            },

            title: {
                text: '',
                style: {
                    color: 'black',
                    fontWeight: 'normal'
                }
            },
            credits: { enabled: false },
            xAxis: [{

                title: {
                    text: selectedChart.UnitOfMeasurement,
                    style: {
                        color: '#666666',
                        fontWeight: 'bold',
                        fontSize: '11pt',
                        fontFamily: 'Arial, sans-serif'
                    }
                },
                categories: languageCode == "cy" ? selectedChart.lstSeries[0].yearsWelsh : selectedChart.lstSeries[0].years,
                reversed: false,
                labels: {
                    style: {
                        color: '#666666',
                        fontWeight: 'normal',
                        fontSize: '10pt',
                        fontFamily: 'Arial, sans-serif'
                    }
                }
            }
            ],
            yAxis: {
                title: {
                    text: txtArr[0],
                    style: {
                        color: '#666666',
                        fontWeight: 'bold',
                        fontSize: '11pt',
                        fontFamily: 'Arial, sans-serif'
                    }
                },
                max: maxCount,
                tickInterval: tickInterval,
                min: (maxCount * -1),
                labels: {
                    style: {
                        color: '#666666',
                        fontWeight: 'normal',
                        fontSize: '10pt',
                        fontFamily: 'Arial, sans-serif'
                    },
                    formatter: function () { return (Math.abs(this.value)); }
                }

            },
            plotOptions: { series: { stacking: 'normal' }, shadow: false },
            tooltip: {

                enabled: true,
                formatter: function () {

                    if (this.y < 0) {
                        this.y = this.y * -1;
                    }

                    var s = '<span style="color:\'#666666\'; font-weight:bold; font-size: 11pt; font-family: Arial, sans-serif;">' + this.x + '</span><br /> <span style="color: \'#666666\';">' + this.series.name + ': </span>' +
                        '<span style="font-size: 10pt; font-family: Arial, sans-serif;">' + this.y.toFixed(0).toString() + '</span>';


                    return s;

                }
            },
            series: seriesData,
            // Default colors from Highcharts v2. See https://api.highcharts.com/highcharts/colors
            colors: ['#4572A7', '#AA4643', '#89A54E', '#80699B', '#3D96AE', '#DB843D', '#92A8CD', '#A47D7C', '#B5CA92']
        });

    }
    else if (jsonModel.SubjectName == "S52O") {
        // Check if the subject name is S520 (this is a finance indicator)
        // var w = window.innerWidth;
        // Create an array to hold the series list
        var seriesList = [];

        // Loop through each record in jsonModel.lstSeries
        for (var i = 0; i < selectedChart.lstSeries.length; i++) {

            // Check if the english name is school
            if (selectedChart.lstSeries[i].nameEnglish == "School") {
                // Allocate this record to the seriesList array as only the school data should be displayed on
                // the chart
                seriesList.push(selectedChart.lstSeries[i]);
                // Break out of the loop as it is no longer required
                break;
            }
        }

        // Create the chart
        chart = new Highcharts.Chart({
            chart: {
                type: 'column',
                renderTo: 'ind' + indicatorId
            },
            title: {
                text: ''
            },
            credits: { enabled: false },
            legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom',
                itemStyle: {
                    color: '#333333',
                    fontFamily: 'Arial, sans-serif',
                    fontSize: '9pt'
                },
                padding: 14,
                itemMarginTop: 4,
                itemMarginBottom: 0,
                itemWidth: 136,
                borderRadius: 0,
                borderColor: '#f6f6f6',
                backgroundColor: '#f6f6f6',
                borderWidth: 4,
                x: -6,
                y: 0,
                width: w < 500 ? 100 : 140,
                reversed: false,
                enabled: true
            },
            xAxis: {
                categories: selectedChart.lstYears,
                crosshair: true
            },
            yAxis: {
                title: {
                    text: selectedChart.UnitOfMeasurement
                }
            },
            tooltip: {
                //animation: true,
                //shared: true,
                enabled: true,
                formatter: function () {


                    var s = '<span style="color:\'#666666\'; font-weight:bold; font-size: 11pt; font-family: Arial, sans-serif;">' + this.x + '</span><br /> <span style="color: \'#666666\';">' + this.series.name + ': </span>' +
                        '<span style="font-size: 10pt; font-family: Arial, sans-serif;">' + jsonModel.SymbolOfMeasurement + this.y.toFixed(0).toString() + '</span>';


                    return s;

                }
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: seriesList
        });
    }
    else if (jsonModel.SubjectName == "PA") {
        // Check if the subject name is PA (the total achieved expected level indicators)
        // This requires a column chart

        // Create chart
        chart = new Highcharts.Chart({
            chart: {
                type: 'column',
                renderTo: 'ind' + indicatorId,
            },
            title: { text: '' },
            credits: { enabled: false },
            legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom',
                itemStyle: {
                    color: '#333333',
                    fontFamily: 'Arial, sans-serif',
                    fontSize: w < 750 ? '8pt' : '9pt'
                },
                padding: 14,
                itemMarginTop: 4,
                itemMarginBottom: 0,
                itemWidth: 136,
                borderRadius: 0,
                borderColor: '#f6f6f6',
                backgroundColor: '#f6f6f6',
                borderWidth: 4,
                x: -6,
                width: w < 750 ? 100 : 140,
                reversed: false,
                enabled: true
            },
            xAxis: {
                categories: languageCode == "cy" ? selectedChart.lstYearsWelsh : selectedChart.lstYears,
                crosshair: true,
                labels: {
                    rotation: w < 400 ? 90 : null,
                    formatter: w < 400 ? function () {
                        return this.value.replace(/ /g, '<br />');
                    } : null,
                    y: w < 400 ? 40 : 10,
                    x: w < 400 ? 10 : 0

                }
            },
            yAxis: {
                min: 0,
                max: 101,
                endOnTick: false,
                title: {
                    text: selectedChart.UnitOfMeasurement
                }
            },
            tooltip: {
                valueDecimals: 1,
                valueSuffix: selectedChart.SymbolOfMeasurement
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: selectedChart.lstSeries
        });
    }
    else {
        // This is for all other indicators as the charts should be generic

        // Set some variables up to set max, min, and tick interval values for the chart
        var min, max, tickInterval;
        min = 0;

        // Some checking and setting of these values depending on subjects and key stages
        if (jsonModel.SubjectName == "ATT") {
            // increase range (and update interval to work with range) for secondary school attendance data 
            // 22 = % Attendance during the year (Primary only)
            // 23 = % Attendance during the year (Secondary only)
            // 24 = Benchmarking - % Attendance during the year (Secondary only)
            // 43 = Benchmarking - % Attendance during the year (Primary only)
            const graphsWithIncreasedRanges = ['22', '23', '24', '43']
            if (graphsWithIncreasedRanges.includes(indicatorId)) {
                min = 70;
                tickInterval = 5;
            } else {
                min = 80;
                tickInterval = 4;
            }

            max = 100.1;
        }
        else if (jsonModel.SubjectName == "tot") {
            if (jsonModel.KeyStageId == 5) {
                max = null;
            }
            else {
                max = 500; // this was 400 but was causing issues because some values were > 400
            }
            tickInterval = 100;
        }
        else if (jsonModel.SubjectName == "S52B" || jsonModel.SectionId == 2 || jsonModel.SectionId == 3) {
            max = null;
            tickInterval = null;
        }
        else {
            max = 100.5;
            tickInterval = 10;
        }

        // Find the table which displays the data and allocate it to a variable
        var table = $("#atbl" + chartId);

        // If the section is benchmarking, set the series colour of school total to be black
        if (jsonModel.SectionId == 4) {
            for (var i = 0; i < selectedChart.lstSeries.length; i++) {
                if (selectedChart.lstSeries[i].nameEnglish == "School - Total") {
                    selectedChart.lstSeries[i].color = "#000000";
                }
                else {
                    selectedChart.lstSeries[i].enableMouseTracking = false;
                }
            }
        }

        // Set chart width depending on screen size

        // Set legend item width depending on screen size
        let itemWidth = 0;
        if (w < 500 && jsonModel.SubjectName.indexOf("SEN") != -1)
            itemWidth = 300;
        else if (w < 750 && jsonModel.SubjectName.indexOf("SEN") != -1)
            itemWidth = 250;
        else if (w < 750 && selectedChart.lstSeries.length > 3)
            itemWidth = 170;
        else if (w < 750)
            itemWidth = 155;
        else if (w < 800 && selectedChart.lstSeries.length > 3 && jsonModel.SubjectName.indexOf("SEN") == -1)
            itemWidth = 200;
        else if (w < 850 && selectedChart.lstSeries.length > 3 && jsonModel.SubjectName.indexOf("SEN") == -1)
            itemWidth = 180;
        else if (w < 910 && selectedChart.lstSeries.length > 3 && jsonModel.SubjectName.indexOf("SEN") == -1)
            itemWidth = 180;
        else if (jsonModel.SubjectName.indexOf("SEN") != -1)
            itemWidth = 250;
        else if (selectedChart.lstSeries.length > 3)
            itemWidth = 200;
        else
            itemWidth = 136;

        // Set x value for placement of legend depending on screen size
        let xValue = 0;
        if (w < 750 && jsonModel.SubjectName.indexOf("SEN") != -1){
            xValue = 10;
        }

        // Create the chart
        chart = new Highcharts.Chart({
            chart: {
                backgroundColor: 'none',
                renderTo: 'ind' + indicatorId,
                type: 'area',
                height: 445,
            },
            title: {
                text: ''
            },
            legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom',
                itemStyle: {
                    color: '#333333',
                    fontFamily: 'Arial, sans-serif',
                    fontSize: w < 750 ? '7pt' : '9pt',
                    verticalAlign: 'top',
                    y: -10
                },
                padding: w < 750 ? 5 : 10,
                itemMarginTop: 4,
                itemMarginBottom: 0,
                itemWidth: itemWidth,
                borderRadius: 0,
                borderColor: '#f6f6f6',
                backgroundColor: '#f6f6f6',
                x: xValue,
                reversed: false,
                enabled: jsonModel.SectionId == 4 ? false : true
            },
            credits: { enabled: false },
            xAxis: {
                title: {
                    enabled: false
                },
                categories: selectedChart.lstYears,
                reversed: false,
                labels: {
                    style: {
                        color: '#666666',
                        fontWeight: w < 500 ? null : 'bold',
                        fontSize: w < 500 ? '8pt' : '10pt',
                        fontFamily: 'Arial, sans-serif'
                    }
                },
                lineColor: '#666666',
                lineWidth: 1
            },
            yAxis: {
                endOnTick: false,
                title: {
                    text: selectedChart.SymbolOfMeasurement == null ? selectedChart.UnitOfMeasurement : selectedChart.UnitOfMeasurement + ' (' + selectedChart.SymbolOfMeasurement + ')',
                    style: {
                        color: '#666666',
                        fontWeight: 'bold',
                        fontSize: w < 500 ? '8pt' : '10pt',
                        fontFamily: 'Arial, sans-serif'
                    }
                },
                labels: {
                    style: {
                        color: '#666666',
                        fontWeight: 'normal',
                        fontSize: w < 500 ? '8pt' : '10pt',
                        fontFamily: 'Arial, sans-serif'
                    },
                    formatter: function () {
                        return (Math.abs(this.value));
                    }
                },
                gridLineColor: 'black',
                gridLineWidth: 1,
                min: min,
                max: max,
                tickInterval: tickInterval
            },
            tooltip: {
                //animation: true,
                //shared: true,
                enabled: true,
                formatter: function () {

                    var stringDisplay = "";
                    if (jsonModel.SymbolOfMeasurement == "£") {
                        stringDisplay = selectedChart.SymbolOfMeasurement + this.y.toFixed(0).toString();
                    }
                    else {
                        if (jsonModel.SymbolOfMeasurement == null) {
                            stringDisplay = this.y.toFixed(1).toString();
                        }
                        else {
                            stringDisplay = this.y.toFixed(1).toString() + selectedChart.SymbolOfMeasurement;
                        }
                    }

                    var s = '<span style="color:\'#666666\'; font-weight:bold; font-size: 11pt; font-family: Arial, sans-serif;">' + this.x + '</span><br /> <span style="color: \'#666666\';">' + this.series.name + ': </span>' +
                        '<span style="font-size: 10pt; font-family: Arial, sans-serif;">' + stringDisplay + '</span>';


                    return s;

                },
                valueDecimals: 1,
                valueSuffix: selectedChart.SymbolOfMeasurement
                //useHTML: true,
                //backgroundColor: '#ffffff',
                //borderWidth: 1,
                //borderRadius: 0,
                //borderColor: '#666666',
                //shadow: false
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                    borderWidth: 0,
                    pointWidth: 60
                },
                line: {
                    marker: {
                        symbol: jsonModel.SectionId == 4 ? 'circle' : null
                    }
                },
                area: {
                    stacking: true,
                    lineColor: '#666666',
                    lineWidth: 0,
                    marker: {
                        lineWidth: 0,
                        lineColor: '#666666',
                        radius: 0
                    },
                    areaspline: {
                        stacking: 'normal'
                    }
                },
                series: {
                    shadow: false,
                    stacking: 'normal',
                    fillOpacity: 0.3
                }
            },
            series: selectedChart.lstSeries

        });
        chart.yAxis[0].isDirty = true;

        chart.redraw();
    }

    // Add the chart to the window so it can be referenced globally
    chart.chartId = chartId;
    if (!window.allCharts){
        window.allCharts = [chart];
    } else {
        window.allCharts.push(chart);
    }

    return chart;
}
window.drawChart = drawChart;


/** @typedef {{id:string, leaCode: number, leaNameEnglish: string, leaNameWelsh: string, regionId: number}} Region */

/**
 * Register the typeahead functionality on the global school search bar
 * 
 * @param {Region[]} regions 
 */
function addSchoolSearchTypeahead(regions){

    // Generate the schools list from the json file for auto complete
    $.ajax('../Data/schools.json?111111', {
        success: function (data) {
            // Typeahead functionality for school search
            $('#headerSchoolSearch').typeahead({
                items: 8,
                minLength: 1,
                cancelButton: false,
                resultContainer: $('#header-search-results'),
                source: {
                    data: data.schools,
                    display: "name"
                },
                blurOnTab: true,
                mustSelectItem: true,
                href: (item) => `/School/${item.schoolCode}?lang=${window.languageCode}`,
                template: (query, item) => {
                    const region = getRegionTextForSchool(regions, item.lea, window.languageCode);
                    return `${item.name} ${region}`;
                },
                emptyTemplate: "&nbsp;",
                callback: {
                    onResult: (node, query, result, resultCount) => {
                        const shouldButtonBeDisabled = resultCount === 0;
                        $("#header-search-submit").prop("disabled", shouldButtonBeDisabled);
                    }
                }
            });

            // Toggle results visibility based on search box focus
            $('#headerSchoolSearch').on("blur", () => {
                // Slightly delay the hiding of results
                setTimeout(() => $("#header-search-results .typeahead__list").hide(), 300)
            });
            $('#headerSchoolSearch').on("focus", () => {
                $("#header-search-results .typeahead__list").show()
            });

            // Open first result on click of the search button
            $("#header-search-submit").on("click", () => {
                document.querySelector("#header-search-results .typeahead__list a:first-child").click()
            });

            if($("#exportSchoolSearch")) {
                $('#exportSchoolSearch').typeahead({
                    items: 8,
                    minLength: 1,
                    cancelButton: false,
                    resultContainer: $('#export-search-results'),
                    source: {
                        data: data.schools,
                        display: "name"
                    },
                    blurOnTab: true,
                    mustSelectItem: false,
                    href: (item) => `#`,
                    template: (query, item) => {
                        const region = getRegionTextForSchool(regions, item.lea, window.languageCode);
                        return `${item.name} ${region}`;
                    },
                    emptyTemplate: "&nbsp;",
                    callback: {
                        onResult: (node, query, result, resultCount) => {
                            const shouldButtonBeDisabled = resultCount === 0;
                            $("#export-search-submit").prop("disabled", shouldButtonBeDisabled);
                        },
                        onClick: (node, a, item, event) => {
                            event.preventDefault();
                            appendSchoolsToExport(item.schoolCode, item.name);
                        }
                    }
                });
    
                // Toggle results visibility based on search box focus
                $('#exportSchoolSearch').on("blur", () => {
                    // Slightly delay the hiding of results
                    setTimeout(() => $("#export-search-results .typeahead__list").hide(), 300)
                });
                $('#exportSchoolSearch').on("focus", () => {
                    $("#export-search-results .typeahead__list").show()
                });
    
                // Open first result on click of the search button
                $("#export-search-submit").on("click", () => {
                    document.querySelector("#export-search-results .typeahead__list a:first-child").click()
                });
            }
        },
        error: function (error) {
            console.error('Failed to load schools JSON.', {error});
        }
    });
}

/**
 * Get the region text for a given school
 * 
 * @param {Region[]} regions A list of all regions
 * @param {number} laCode The LA code of the school to lookup
 * @param {"en" | "cy"} language The language to use for the display text
 * @returns {string}
 */
function getRegionTextForSchool(regions, laCode, language){
    const region = regions.find(o => o.leaCode === laCode);
    if (!region){
        return ""
    }

    if (language === "cy"){
        return `(${region.leaNameWelsh})`;
    } else if (language === "en"){
        return `(${region.leaNameEnglish})`;
    } else {
        return "";
    }
}