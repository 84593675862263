
/**
 * Add a given school to the list of schools to export
 * 
 * @param {string} schoolCode The 7 digit school code to add. e.g 6601100
 * @param {string} schoolName The display name of the school
 */
export function appendSchoolsToExport(schoolCode, schoolName) {
    // Clear feedback
    $('.export-feedback').empty();
    $('.export-error').empty();

    // Add row to the school export table
    $('.json_download_table').css({ display: 'block' });
    $(/*html*/`
        <tr data-school-code="${schoolCode}">
            <td>${schoolName}</td>
            <td>
                <button type="button" id="schoolCode" value="Remove" class="button button__feature" onclick="removeExportedSchool('${schoolCode}')">Remove</button>
            </td>
        </tr>
        `)
        .appendTo('#exportedSchools');

    // Add school code to the hidden list of schools to export
    let currentCodes = $('#schoolSearchCodes').val();
    if (currentCodes.length > 0) {
        currentCodes += "," + schoolCode;
    }
    else {
        currentCodes = schoolCode;
    }
    $('#schoolSearchCodes').val(currentCodes);
}

/**
 * Remove a given school from the list of schools to export
 * 
 * @param {string} schoolCode The 7 digit school code to remove. e.g 6601100
 */
function removeExportedSchool(schoolCode) {
    //Remove row from list
    $(`#exportedSchools tr[data-school-code='${schoolCode}']`).each(function () {
        if ($('.json_download_table tr').length == 1) {
            $('.json_download_table').css({ display: 'none' });
        }

        this.remove();
    });
    //Remove code from hidden field
    let currentCodes = $('#schoolSearchCodes').val();
    currentCodes = currentCodes.replace(schoolCode + ',', '');
    currentCodes = currentCodes.replace(',' + schoolCode, '');
    currentCodes = currentCodes.replace(schoolCode, '');
    $('#schoolSearchCodes').val(currentCodes);
}
window.removeExportedSchool = removeExportedSchool;

$(document).ready(function () {
    $(".sector_option").change(function () {
        const currentVal = $("#selectedSectorOption").val();
        if (this.checked) {
            if (!currentVal.indexOf(this.id) >= 0) {
                const newVal = currentVal + this.id;
                $("#selectedSectorOption").val(newVal);
            }
        }
        else {
            if (currentVal.indexOf(this.id) >= 0) {
                const newVal = $("#selectedSectorOption").val().replace(this.id, '');
                $("#selectedSectorOption").val(newVal);
            }
        }
    });

    // On submit of the export form, hide any validation messages
    document.querySelector("#export-schools-form")?.addEventListener("submit", () => {
        $(".validation-summary").hide();
    });

});
